import { render, staticRenderFns } from "./campaignList.vue?vue&type=template&id=511c9e95&scoped=true"
import script from "./campaignList.vue?vue&type=script&lang=ts"
export * from "./campaignList.vue?vue&type=script&lang=ts"
import style0 from "./campaignList.vue?vue&type=style&index=0&id=511c9e95&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511c9e95",
  null
  
)

export default component.exports